import React, { useEffect, useState } from 'react';
import './BarraPesquisa.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const BarraPesquisa = ({ placeholder }) => {
  const [searchText, setSearchText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [previousSearch, setPreviousSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const texto = params.get('texto');
    if (texto) {
      setSearchText(texto);
      setPreviousSearch(texto);
      setFilteredSuggestions([]); // Clear suggestions when text is set from URL
    }
  }, [location.search]);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  useEffect(() => {
    if (searchText.trim() && searchText !== previousSearch) {
      const normalizedSearchText = normalizeText(searchText);
      const filtered = suggestions
        .filter(suggestion => normalizeText(suggestion).includes(normalizedSearchText))
        .sort((a, b) => normalizeText(a).indexOf(normalizedSearchText) - normalizeText(b).indexOf(normalizedSearchText))
        .map(suggestion => matchCase(suggestion, searchText));
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  }, [searchText, suggestions, previousSearch]);

  const fetchSuggestions = async () => {
    try {
      const response = await axios.get('https://ofertas-sisal-dev-0484a41fda01.herokuapp.com/api/offers/suggestions');
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const normalizeText = (text) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  const matchCase = (text, pattern) => {
    const index = normalizeText(text).indexOf(normalizeText(pattern));
    if (index !== -1) {
      const matchedText = text.substr(index, pattern.length);
      const restText = text.substr(index + pattern.length);
      const formattedMatchedText = pattern
        .split('')
        .map((char, i) => (char === char.toUpperCase() ? matchedText[i].toUpperCase() : matchedText[i].toLowerCase()))
        .join('');
      return text.substr(0, index) + formattedMatchedText + restText;
    }
    return text;
  };

  const handleSearch = () => {
    if (searchText.trim()) {
      navigate(`/pesquisa?texto=${encodeURIComponent(searchText)}`);
      setPreviousSearch(searchText); // Update previous search text
      setFilteredSuggestions([]); // Clear suggestions after search
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchText(suggestion);
    navigate(`/pesquisa?texto=${encodeURIComponent(suggestion)}`);
    setPreviousSearch(suggestion); // Update previous search text
    setFilteredSuggestions([]); // Clear suggestions after click
  };

  return (
    <div>
      <div className="barra-pesquisa">
        <input
          type="text"
          className="barra-pesquisa-input"
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button className="barra-pesquisa-button" onClick={handleSearch}>
          <FontAwesomeIcon icon={faSearch} className="inverted-icon" />
        </button>
        {filteredSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {filteredSuggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BarraPesquisa;
