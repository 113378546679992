import React from 'react';
import './RegrasCupom.css';

const RegrasCupom = ({ regras }) => {
  return (
    <div className="regras-cupom">
      <h3>* Regras para uso:</h3>
      <ul>
        {regras.map((regra, index) => (
          <li key={index}>{regra}</li>
        ))}
      </ul>
    </div>
  );
};

export default RegrasCupom;
