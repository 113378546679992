import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './MiniHeader.css';

const MiniHeader = () => {
  const navigate = useNavigate();

  const handleVoltarClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="mini-header-container">
      <div className="voltar-icon" onClick={handleVoltarClick}>
        <FontAwesomeIcon icon={faArrowLeft} size="2x" />
      </div>
      <div className="logo" onClick={handleLogoClick}>
        <span className="logo-bold">Ofertas</span>
        <span className="logo-normal">do Sisal</span>
      </div>
    </div>
  );
};

export default MiniHeader;
