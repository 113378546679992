import React, { useEffect, useState } from 'react';
import OfertaCardResumo from '../card-oferta-resumo/OfertaCardResumo';
import './ListagemOfertasCardResumo.css';
import InfiniteScroll from 'react-infinite-scroll-component';

const ListagemOfertasCardResumo = ({ textoPesquisa }) => {
  const [ofertas, setOfertas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const limit = 5; // Limite de itens por requisição
  const [endpoints, setEndpoints] = useState([]);

  const fetchAllOfertas = (offset) => {
    let endpoint = `https://ofertas-sisal-dev-0484a41fda01.herokuapp.com/api/offers/search?limit=${limit}&offset=${offset}`;
    if (textoPesquisa) {
      endpoint += `&q=${encodeURIComponent(textoPesquisa)}`;
    }
    //se o endpoint já foi chamado, não chama novamente
    if (endpoints.includes(endpoint)) {
      return;
    }
    setEndpoints([...endpoints, endpoint]);

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setOfertas(prevOfertas => [...prevOfertas, ...data]);
        setIsLoading(false);
        if (data.length < limit) {
          setHasMore(false); // Se o número de itens retornados for menor que o limite, não há mais itens a carregar
        }
      })
      .catch(error => {
        console.error('Error fetching ofertas:', error);
        setIsLoading(false);
        setHasMore(false);
      });
  };

  useEffect(() => {
    if (textoPesquisa === undefined) {
      return;
    }
    fetchAllOfertas(offset);
  }, [offset, textoPesquisa]);

  useEffect(() => {
    setOfertas([]);
  }, [ textoPesquisa]);

  const fetchMoreData = () => {
    setOffset(prevOffset => prevOffset + limit);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (isLoading && ofertas.length === 0) {
    return <div className="loading">Carregando...</div>; // Efeito de carregamento
  }

  return (
    <div className="titulo-com-ofertas">
      <InfiniteScroll
        dataLength={ofertas.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<div className="loading">Carregando mais ofertas...</div>}
        endMessage={
          <div className="end-message">
            <p>Você viu todas as ofertas!</p>
            <p className="voltar-inicio" onClick={scrollToTop}>
              Voltar para o início
            </p>
          </div>
        }
      >
        <div className="listagem-ofertas-card-resumo">
          {ofertas.map((oferta, index) => (
            oferta.cupom ? (
              <OfertaCardResumo
                key={index}
                id={oferta._id}
                logo={oferta.estabelecimento["link-imagem"]}
                titulo={oferta.cupom.titulo}
                estabelecimento={oferta.estabelecimento["nome-estabelecimento"]}
                endereco={`${oferta.estabelecimento.endereço.rua}, ${oferta.estabelecimento.endereço.numero}`}
                buscasQtd={typeof oferta.estabelecimento["buscas-qtd"] === 'object' ? parseInt(oferta.estabelecimento["buscas-qtd"]["$numberInt"], 10) : oferta.estabelecimento["buscas-qtd"]}
                onClickResgatar={() => window.location.href = `/oferta/${oferta._id}`}
              />
            ) : null
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ListagemOfertasCardResumo;
