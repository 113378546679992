import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './DadosEstabelecimento.css';
import CardEstabelecimento from './CardEstabelecimento';

const DadosEstabelecimento = ({ nome, buscasQtd, endereco, logo }) => {
  const getStatusText = (buscasQtd) => {
    if (buscasQtd > 200) {
      return 'Bombando';
    } else if (buscasQtd > 100) {
      return 'Popular';
    } else if (buscasQtd > 20) {
      return 'Top';
    } else if (buscasQtd > 0) {
      return 'Esquentando';
    } else {
      return 'Novo';
    }
  };

  return (
    <div className="dados-estabelecimento">
      <h1 className="estabelecimento-title">{nome}</h1>
      <p className="estabelecimento-searches">
        <FontAwesomeIcon icon={faSearch} /> {getStatusText(buscasQtd)}: {buscasQtd} buscas
      </p>
      <p className="estabelecimento-address">{`${endereco.rua}, número ${endereco.numero}`}</p>
      <CardEstabelecimento logo={logo} estabelecimento={nome} />
    </div>
  );
};

export default DadosEstabelecimento;
