import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './OfertaCompleta.css';
import DadosEstabelecimento from '../dados-estabelecimento/DadosEstabelecimento';
import Cupom from '../cupom/Cupom';
import RegrasCupom from '../regras-cupom/RegrasCupom';
import ContatoEstabelecimento from '../contato-estabelecimento/ContatoEstabelecimento';
import BarraPesquisa from '../barra-pesquisa/BarraPesquisa';
import MiniHeader from '../mini-header/MiniHeader';

const OfertaCompleta = () => {
  const { id } = useParams();
  const [oferta, setOferta] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`https://ofertas-sisal-dev-0484a41fda01.herokuapp.com/api/offers/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setOferta(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching oferta:', error);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <div className="loading">Carregando...</div>; // Efeito de carregamento
  }

  if (!oferta) {
    return <div>Oferta não encontrada</div>; // Texto puro de oferta não encontrada
  }

  const {
    'nome-estabelecimento': nome_estabelecimento = 'Nome do estabelecimento indisponível',
    'buscas-qtd': buscas_qtd = 'Quantidade de buscas indisponível',
    endereço: {
      rua = 'Rua indisponível',
      numero = 'Número indisponível',
      cidade = 'Cidade indisponível',
      estado = 'Estado indisponível',
      cep = 'CEP indisponível'
    } = {},
    'link-imagem': linkImagem = '',
    links = {}
  } = oferta.estabelecimento || {};

  const {
    titulo = 'Título do cupom indisponível',
    codigo = 'Código do cupom indisponível',
    regras = ['Regras indisponíveis']
  } = oferta.cupom || {};

  // Caminho direto para a imagem no diretório público
  const logoPath = linkImagem.trim() ? `${linkImagem.trim()}` : 'geral.jpg';

  const enderecoCompleto = {
    rua,
    numero,
    cidade,
    estado,
    cep
  };

  return (
    <div>
      <MiniHeader />
      <BarraPesquisa placeholder="Quer encontrar outras ofertas?" />
      <DadosEstabelecimento 
        nome={nome_estabelecimento} 
        buscasQtd={buscas_qtd} 
        endereco={enderecoCompleto} 
        logo={logoPath} 
      />
      <Cupom 
        titulo={titulo} 
        codigo={codigo} 
        estabelecimento={nome_estabelecimento} 
      />
      <RegrasCupom regras={regras} />
      {Object.keys(links).length > 0 && <ContatoEstabelecimento links={links} />}

      <div className="end-message">
        <p className="voltar-inicio" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Voltar para o início
        </p>
      </div>
    </div>
  );
};

export default OfertaCompleta;
