import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faFire, faTag, faSearch, faComments } from '@fortawesome/free-solid-svg-icons';
import './TituloComIcone.css';

const iconMap = {
  faFire: faFire,
  faTag: faTag,
  faSearch: faSearch,
  faCart: faCartShopping,
  faComments: faComments
};

const TituloComIcone = ({ icon, title, fontSize = '24px' }) => {
  return (
    <div>
      <h2 className="titulo-com-icone" style={{ fontSize }}>
        <FontAwesomeIcon icon={iconMap[icon]} className="fa-icon" /> {title}
      </h2>
    </div>
  );
};

TituloComIcone.propTypes = {
  icon: PropTypes.oneOf(['faFire', 'faTag', 'faSearch', 'faCart', 'faComments']).isRequired,
  title: PropTypes.string.isRequired,
  fontSize: PropTypes.string // Adicionando prop para tamanho da fonte
};

export default TituloComIcone;
