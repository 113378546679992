import React from 'react';
import './ContatoEstabelecimento.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faShippingFast, faComments } from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  WhatsApp: faWhatsapp,
  Instagram: faInstagram,
  Entrega: faShippingFast,
  Site: faGlobe
};

const order = ['WhatsApp', 'Instagram', 'Entrega', 'Site'];

const ContatoEstabelecimento = ({ links }) => {
  return (
    <div className="contato-estabelecimento">
      <h3><FontAwesomeIcon icon={faComments} /> Fale com o estabelecimento</h3>
      <div className="contato-botoes">
        {order.map(name => (
          links[name] && (
            <button key={name} className={`contato-botao ${name.toLowerCase()}`} onClick={() => window.open(links[name], '_blank')}>
              <FontAwesomeIcon icon={iconMap[name]} /> {name}
            </button>
          )
        ))}
      </div>
    </div>
  );
};

export default ContatoEstabelecimento;
