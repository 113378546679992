import React from 'react';
import './PaginaInicial.css';
import Header from '../../components/header/Header';
import BarraPesquisa from '../../components/barra-pesquisa/BarraPesquisa';
import Categorias from '../../components/categorias/Categorias';
import ListagemOfertasCardResumo from '../../components/listagem-card-oferta-resumo/ListagemOfertasCardResumo';
import TituloComIcone from '../../components/titulo-com-icone/TituloComIcone';

const PaginaInicial = () => {
    return (
        <div>
            <Header />
            <BarraPesquisa placeholder="O que você está procurando?" />
            <Categorias />
            <TituloComIcone icon="faCart" title={"Todas as ofertas"} />
            <ListagemOfertasCardResumo textoPesquisa=""/>
        </div>
    );
};

export default PaginaInicial;
