import React from 'react';
import './OfertaDetalhada.css';
import OfertaCompleta from '../../components/oferta-completa/OfertaCompleta';

const PaginaOfertaDetalhada = () => {
    return (
        <div className="oferta-detalhada">
            <OfertaCompleta />
        </div>
    );
};

export default PaginaOfertaDetalhada;
