import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaginaOfertaDetalhada from './paginas/oferta-detalhada/PaginaOfertaDetalhada';
import PaginaInicial from './paginas/inicial/PaginaInicial';
import PaginaPesquisa from './paginas/pesquisa/PaginaPesquisa';
// Importe outros componentes se necessário

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/oferta/:id" element={<PaginaOfertaDetalhada />} />
      <Route path="/" element={<PaginaInicial />} />
      <Route path="/pesquisa" element={<PaginaPesquisa />} />
        {/* Adicione outras rotas aqui */}
      </Routes>
    </Router>
  );
};

export default App;