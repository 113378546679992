import React, { useEffect, useState } from 'react';
import './PaginaPesquisa.css';
import MiniHeader from '../../components/mini-header/MiniHeader';
import BarraPesquisa from '../../components/barra-pesquisa/BarraPesquisa';
import TituloComIcone from '../../components/titulo-com-icone/TituloComIcone';
import ListagemOfertasCardResumo from '../../components/listagem-card-oferta-resumo/ListagemOfertasCardResumo';
import { useLocation } from 'react-router-dom';

const PaginaPesquisa = () => {
  const [searchQuery, setSearchQuery] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const texto = params.get('texto');
    if (texto) {
      setSearchQuery(texto);
    }
  }, [location.search]);

  return (
    <div>
      <MiniHeader />
      <BarraPesquisa placeholder="O que você está procurando?" />
      <TituloComIcone icon="faSearch" title={`Resultados para "${searchQuery}"`} fontSize="16px" />
      <ListagemOfertasCardResumo textoPesquisa={searchQuery}/>
    </div>
  );
};

export default PaginaPesquisa;
