import React from 'react';
import './CardEstabelecimento.css';

const CardEstabelecimento = ({ logo, estabelecimento }) => {
  const handleImageError = (event) => {
    event.target.src = '/img/geral.jpg'; // Substitua pelo caminho da imagem padrão
  };

  logo = '/img/' + logo
  
  return (
    <div className="card-estabelecimento">
      <img src={logo} alt={estabelecimento} className="card-logo" onError={handleImageError} />
    </div>
  );
};

export default CardEstabelecimento;
