import React from 'react';
import './OfertaCardResumo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faFire } from '@fortawesome/free-solid-svg-icons';
import CardEstabelecimento from '../dados-estabelecimento/CardEstabelecimento';

const OfertaCardResumo = ({ id, logo, titulo, estabelecimento, endereco, buscasQtd, onClickResgatar }) => {
  // Verifica se todas as informações estão presentes
  if (!logo || !titulo || !estabelecimento || !endereco || !buscasQtd) {
    console.log("Alguma informação faltando")
    console.log("logo: " + logo + " titulo: " + titulo + " estabelecimento: " + estabelecimento + " endereco: " + endereco + " buscasQtd: " + buscasQtd)
    return null; // Não renderiza o card se faltar alguma informação
  }

  const getStatusText = (buscasQtd) => {
    if (buscasQtd > 200) {
      return 'Bombando';
    } else if (buscasQtd > 100) {
      return 'Popular';
    } else if (buscasQtd > 20) {
      return 'Top';
    } else if (buscasQtd > 0) {
      return 'Esquentando';
    } else {
      return 'Novo';
    }
  };

  return (
    <div className="oferta-card-resumo" onClick={() => window.location.href = `/oferta/${id}`}>
      <CardEstabelecimento logo={logo} estabelecimento={estabelecimento}/>
      <div className="oferta-detalhes">
        <h1 className="oferta-titulo"><strong>{titulo}</strong></h1>
        <div className="oferta-top-buscas"><FontAwesomeIcon icon={faFire} /> <strong>{getStatusText(buscasQtd)}: {buscasQtd} buscas</strong> </div>
        <div className="oferta-estabelecimento"><strong>{estabelecimento}</strong></div>
        <div className="oferta-endereco">{endereco}</div>
      </div>
      <button className="resgatar-button" onClick={onClickResgatar}>
      <FontAwesomeIcon icon={faTag} />
        <span className="resgatar-icone"></span> RESGATAR AGORA
      </button>
    </div>
  );
};

export default OfertaCardResumo;
