import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPizzaSlice, faDollarSign, faGlobe, faHamburger, faClinicMedical, faAppleAlt, faCar, faPlane, faShoppingCart, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './Categorias.css';

const categorias = [
  { nome: 'Pizza', icone: faPizzaSlice },
  { nome: 'Empréstimo', icone: faDollarSign },
  { nome: 'Internet', icone: faGlobe },
  { nome: 'Hambúrguer', icone: faHamburger },
  { nome: 'Clínica', icone: faClinicMedical },
  { nome: 'Frutas', icone: faAppleAlt },
  { nome: 'Carros', icone: faCar },
  { nome: 'Viagens', icone: faPlane },
  { nome: 'Compras', icone: faShoppingCart },
  { nome: 'Café', icone: faCoffee }
];

const Categorias = () => {
  const navigate = useNavigate();

  const handleCategoriaClick = (categoria) => {
    navigate(`/pesquisa?texto=${categoria}`);
  };

  return (
    <div className="categorias-section">
      <div className="categorias-titulo">Categorias</div>
      <div className="categorias-container">
        {categorias.map((categoria) => (
          <div
            key={categoria.nome}
            className="categoria"
            onClick={() => handleCategoriaClick(categoria.nome)}
          >
            <div className="categoria-icone">
              <FontAwesomeIcon icon={categoria.icone} />
            </div>
            <div className="categoria-nome">{categoria.nome}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categorias;
