import React from 'react';
import './Cupom.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

const Cupom = ({ titulo, codigo, estabelecimento }) => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Ofertas do Sisal',
        text: `Use o cupom #${codigo.toUpperCase()} para obter ${titulo.toUpperCase()} em ${estabelecimento}. *Consulte as regras no site.`,
        url: window.location.href,
      }).catch(error => console.log('Error sharing', error));
    } else {
      alert('Compartilhamento não suportado neste navegador.');
    }
  };

  return (
    <div className="cupom-container">
      <h2>{titulo.toUpperCase()}</h2>
      <div className="cupom-card-container">
        <p className="cupom-label">Cupom:</p>
        <div className="cupom-card">
          <div className="cupom-code">{codigo.toUpperCase()}</div>
        </div>
      </div>
      <div className="share-button" onClick={handleShare}>
        <FontAwesomeIcon icon={faShareAlt} /> Compartilhar
      </div>
    </div>
  );
};

export default Cupom;
