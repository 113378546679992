import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header-container">
      <img src="/img/logo-ofertas.png" alt="Logo" className="header-logo" />
    </header>
  );
};

export default Header;
